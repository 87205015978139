module.exports={
"label.account.name":`Имя`,
"label.account.copy":`Пожалуйста, скопируйте случайный проверочный код в электронном письме и нажмите на ссылку для изменения пароля на следующем шаге.`,
"label.account.phone.length":`Номер мобильного телефона должен содержать не менее 7 символов`,
"label.account.Start.trial":`ПОДПИШИСЬ НА`,
"label.account.employees1000":`1000  сотрудников`,
"label.account.other.Ways":`Другие способы входа в систему`,
"label.account.emailsync.button.cancel":`Отмена`,
"label.account.rule":`Состоит из 816 цифр и букв.`,
"label.account.password.update.success":`Пароль успешно изменен.`,
"label.account.describe":`CloudCC фокусируется на цифровой персонализации, аналитическом интеллекте и глобальной связности и настраивается по требованию вместе с развитием бизнеса предприятия.`,
"label.account.enter.Emailbox":`Пожалуйста, введите свой адрес электронной почты.`,
"label.account.password":`Пароль`,
"label.account.problem":`Вход в систему не удался.`,
"label.account.Complete.reset":`Электронная почта. Введите проверочный код, чтобы завершить сброс пароля.`,
"label.account.crm.used":`CRM, используемая компаниями, акции которых котируются на бирже`,
"label.account.report.loading":``,
"label.account.notcustomer":`Нет счета?`,
"label.account.employees501":`5011000 сотрудников`,
"label.account.title.operationManager":`Менеджер по эксплуатации`,
"label.account.Preset":`Предустановленные инструменты управления проектами`,
"label.account.company":`Компания`,
"label.account.come":`Добро пожаловать на платформу Cloudcc Customer Success Platform. Наша новая молниеносная платформа предоставляет вам самое быстрое и полное решение, позволяющее вам делать все так, чтобы ваш клиент был в центре внимания.  `,
"label.account.input.password":`Пожалуйста, введите пароль.`,
"label.account.emailobject.emaildetail.button.back":`Назад`,
"label.account.title.itManager":`ITменеджер`,
"label.account.Modified.successfully":`Пароль успешно изменен. Пожалуйста, нажмите Войти еще раз, чтобы вернуться на страницу входа в систему.`,
"label.account.only11":`Пожалуйста, введите не менее 7 цифр.`,
"label.account.signin":`Вход в систему`,
"label.account.register":`Зарегистрируйтесь, чтобы получить`,
"label.account.adopt":`Проверка пройдена!`,
"label.account.title.hrManager":`Менеджер по маркетингу/HR`,
"label.account.input.usernames":`Пожалуйста, введите ваше имя пользователя.`,
"label.account.only.Numbers":`Номер телефона можно вводить только цифры.`,
"label.account.title.salesManager":`Менеджер по продажам`,
"label.account.email":`Электронная почта`,
"label.account.click":`Нажмите`,
"label.account.and":`и`,
"label.account.mobile":`Телефон`,
"label.account.Privacy.policy":`Политика конфиденциальности`,
"label.account.password.isnull":`Пароль не может быть пустым.`,
"label.account.employees101":`101500 сотрудников`,
"label.account.remember.name":`Запомнить имя пользователя`,
"label.account.Select.country":`Пожалуйста, выберите свою страну/регион.`,
"label.account.distinguish":`Пароль чувствителен к регистру.`,
"label.account.fillMobile":`Пожалуйста, введите свой номер телефона.`,
"label.account.Preconfigured":`Предварительно настроенные отчеты и информационные панели`,
"label.account.Select.job":`Пожалуйста, выберите свою должность.`,
"label.account.employees21":`21100 сотрудников`,
"label.account.username":`Имя пользователя`,
"label.account.Submit":`Отправить`,
"label.account.confirm":`Подтверждение счета`,
"label.account.contact":`Связаться с нами`,
"label.account.country":`Страна`,
"label.account.username.isnull":`Имя пользователя не может быть пустым.`,
"label.account.forget.password":`Забыли пароль?`,
"label.account.Loading":`Загрузка`,
"label.account.goon":`Продолжить`,
"label.account.Register.trial":`Зарегистрируйтесь для бесплатной пробной версии`,
"label.account.title.other":`Другое`,
"label.account.surname":`Фамилия`,
"label.account.Fail":`Верификация не прошла, пожалуйста, попробуйте еще раз.`,
"label.account.Terms.service":`Условия предоставления услуг`,
"label.account.Select.staff":`Пожалуйста, выберите количество сотрудников.`,
"label.account.username.enter":`Пожалуйста, введите свою фамилию.`,
"label.account.trial":`Бесплатная пробная версия`,
"label.account.employees1":`120 сотрудников`,
"label.account.Quick.registration":`или получить быстрый доступ`,
"label.account.tabpage.ok":`Подтвердить`,
"label.account.normal.reregister":`Повторите вход в систему`,
"label.account.batchadd.newbatchpage.save.em":`Формат электронной почты неверен.`,
"label.account..user.title":`Название должности`,
"label.account.No.credit.card.required":`Кредитная карта не требуется.`,
"label.account.enterprise.Wechat":`WeCom`,
"label.account.sample.data":`Предварительная загрузка данных образца`,
"label.account.title.hobbies":`Хобби`,
"label.account.platform":`Новая интеллектуальная, мобильная и цифровая CRMплатформа`,
"label.account.sent":`Электронная почта отправлена`,
"label.account.Confirm.password":`Пожалуйста, подтвердите свой пароль.`,
"label.account.fill":`Пожалуйста, заполните все.`,
"label.account.user.resetpw":`Сброс пароля`,
"label.account.Spell":`Перетащите ползунок, чтобы завершить головоломку.`,
"label.account.identifying.code":`Пожалуйста, введите проверочный код.`,
"label.account.staff":`Сотрудники`,
"label.account.welcome":`Добро пожаловать в CloudCC`,
"label.account.online.guidance":`Онлайнконсультации и обучение`,
"label.account.login.CloudCC":`Вход в CloudCC`,
"label.account.Company.name":`Пожалуйста, введите название вашей компании.`,
"label.account.title.generalManager":`Генеральный директор`,
"label.account.enter.user":`Чтобы сбросить пароль, введите свое имя пользователя CloudCC.`,
"label.account.sent.code":`Проверочный код был отправлен на`,
"label.account.pw.error.text":`Два введенных вами пароля не совпадают.`,
"label.account.title.customerManager":`Менеджер по работе с клиентами`,
"label.account.label.captcha":`Проверочный код`,
"label.account.Trial.days":`Зарегистрируйтесь для 30дневной бесплатной пробной версии`,
"label.account.please.enter.name":`Пожалуйста, введите ваше имя.`,
"label.account.inspect":`Если вы не получили письмо с подтверждением кода проверки, проверьте спам.`,
"label.account.User.rule":`Имя пользователя в виде адреса электронной почты.`,
"label.account.go.Login":`У вас уже есть аккаунт?`,
"label.account.Reading.consent":`Я согласен с`,
"label.account.Change.Password2":`Изменить пароль`,
"label.account.Loginby":`Вход через`,
"label.account.apply.partner":`Подайте заявку на участие в программе CloudCC Partner`,
"label.account.MobileLogin":`Мобильный`,
"label.account.Registration":``,
"label.account.Italy":`Италия`,
"label.account.view.details":`Посмотреть детали`,
"label.account.accept":`Пожалуйста, прочитайте и примите Условия предоставления услуг и Политику конфиденциальности`,
"label.account.China.Mainland":`материковый Китай`,
"label.account.NewPassword":`Пожалуйста, введите новый пароль`,
"label.account.Mobile.not.empty":`Номер мобильного телефона не может быть пустым`,
"label.account.Please.select":`Пожалуйста, выберите`,
"label.account.market.introduce":`Единая платформа маркетинговых каналов поможет вам завоевать больше лидов`,
"label.account.IOS":`Сканирование для загрузки приложения для iOS`,
"label.account.failsend":`Не удалось отправить`,
"label.account.UpperLowerlAphanumeric.character":`Требуются прописные и строчные буквы, цифры и символы`,
"label.account.New.Password1":`Новый пароль`,
"label.account.succSend":`Вам было отправлено электронное письмо. Пожалуйста, скопируйте проверочный код в письме и сбросьте пароль.`,
"label.account.Account.mobile":`Номер счета или мобильного телефона`,
"label.account.Login.consent":`Входя в систему, вы соглашаетесь с тем, что`,
"label.account.fillAreaCode":`Пожалуйста, введите код региона`,
"label.account.active.login":``,
"label.account.jp":`Япония`,
"label.account.partner.success1":`Вы успешно зарегистрировались в качестве партнера CloudCC.`,
"label.account.Reset":`Сброс`,
"label.account.no.user.info":``,
"label.account.EnterOldPass":`Пожалуйста, введите старый пароль`,
"label.account.Germany":`Германия`,
"label.account.title.content":`Зарегистрируйтесь в CloudCC | 30дневная бесплатная пробная версия  попробуйте сейчас!`,
"label.account.Taiwan":`Тайвань, Китай`,
"label.account.China.Station":`Китай`,
"label.account.sale.introduce":`Автоматизация продаж помогает вам выигрывать больше сделок`,
"label.account.Back":`Предыдущий шаг`,
"label.account.market":`Облако маркетинга`,
"label.account.section.company":`Подробная информация о компании`,
"label.account.check.email":`наш менеджер по работе с клиентами свяжется с вами через некоторое время, пожалуйста, проверьте свою почту вовремя`,
"label.account.characters":`5 персонажей`,
"label.account.International":`Международный`,
"label.account.britain":`Великобритания`,
"label.account.reg.fail.msg":`Регистрация не удалась. Пожалуйста, повторите попытку позже.`,
"label.account.success.reg":`вы успешно зарегистрировались`,
"label.account.company.url":`Сайт`,
"label.account.CannotBeEmpty":`Не может быть пустым`,
"label.account.phone.note":``,
"label.account.second":`Через {0} секунд`,
"label.account.Change.Password":`Изменить пароль`,
"label.account.Netherlands":`Нидерланды`,
"label.account.pleaseAgree":`Пожалуйста, примите и согласитесь с Условиями предоставления услуг и Политикой конфиденциальности`,
"label.account.copyright":`CloudCC Inc. Все права защищены`,
"label.account.letter":`1 письмо`,
"label.account.remember":`Теперь я помню.`,
"label.account.accountNum":`Счет`,
"label.account.code.not.empty":`Капча не может быть пустой`,
"label.account.Spain":`Испания`,
"label.account.Alphanumeric":`Необходимо сочетание букв и цифр`,
"label.account.obtain":`Приобрести`,
"label.account.company.txt":`Пожалуйста, добавьте свое имя, если у вас нет зарегистрированного юридического лица`,
"label.account.con.browser":``,
"label.account.Android":`Сканирование для загрузки приложения для Android`,
"label.account.title":`CloudCC Free Trial  CloudCC.com`,
"label.account.partner.success2":`Мы свяжемся с вами через некоторое время.`,
"label.account.Macao":`Макао, Китай`,
"label.account.Brazil":`Бразилия`,
"label.account.service.introduce":`Бесперебойный процесс обслуживания повышает эффективность реагирования`,
"label.account.codeFailure":`Неверный код проверки`,
"label.account.AccountLogin":`Счет`,
"label.account.pswComplexReqRules":`Минимальная длина пароля равна {0}, а {1}`,
"label.account.UpperLowerlAphanumeric":`Требуются прописные и строчные буквы, а также цифры`,
"label.account.active.username":``,
"label.account.rememberPassword":`Запомнить пароль`,
"label.account.HongKong":`Гонконг, Китай`,
"label.account.oldPass":`Старый пароль`,
"label.account.Data.storage":`Ваши данные будут храниться в центре обработки данных {0}.`,
"label.account.number":`1 номер`,
"label.account.Poland":`Польша`,
"label.account.service":`Сервисное облако`,
"label.account.Incorrect.format":`Неправильный формат или номер, пожалуйста, введите заново`,
"label.account.remember.status":`Помни меня`,
"label.account.sale":`Облако продаж`,
"label.account.No.account.binding":`Учетная запись не привязана. Пожалуйста, свяжитесь с администратором`,
"label.account.Russia":`Россия`,
"label.account.France":`Франция`,
"label.account.partner.content":`CloudCC  глобальная компания, которая сейчас набирает партнеров для изучения решений в различных отраслях. Присоединяйтесь к нашей партнерской программе!`,
"label.account.reg.phone.already":`Такая организация уже существует для этого телефона. Войдите в систему или используйте другой телефон для регистрации.`,
"label.account.Confirm":`Подтверждение нового пароля`,
"label.account.VerificationLogin":`Проверить код`,
"label.account.USA":`Соединенные Штаты`,
"label.account.mobile.num":`Пожалуйста, введите номер мобильного телефона`,
"label.account.MinLength":`Минимальная длина пароля составляет`,
"label.account.change.your.password":`Пожалуйста, измените пароль сейчас в целях безопасности`,
"label.account.new.password":`Введите новый пароль для {0}. Убедитесь, что он включает как минимум:`,
"label.account.website.enter":`Пожалуйста, введите адрес официального сайта`,
"label.account.pswComplexIsZero":``
}